<template>
    <v-card class="mb-4">
        <v-card-title class="justify-space-between flex-nowrap">
            <div :title="`${sub.step.name}: ${sub.title}`"
                 class="text-truncate pr-2">
                <span class="mr-2">{{sub.step.name}}:</span><em>{{sub.title}}</em>
            </div>
            <submission-indicators :submission="sub"></submission-indicators>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-space-between flex-nowrap">
            <div class="text-truncate"
                 :title="sub.project.name">{{sub.project.name}}</div>
            <div class=" pt-1">
                <span class="d-none d-md-inline mr-1">Completed:</span>
                <span>{{$displayDate(sub.step.completedOn)}}</span>
            </div>
        </v-card-subtitle>

        <submitter-details v-if="sub.submitter"
                           :submitter="sub.submitter"></submitter-details>

        <v-card-subtitle class="pb-0">Files</v-card-subtitle>
        <file-list :files="sub.files"
                   @file-selected="downloadFile($event)"></file-list>

        <template v-if="sub.step.previousStepDetails">
            <v-card-subtitle class="pb-0">History</v-card-subtitle>
            <previous-step-details :previous-steps="sub.step.previousStepDetails"></previous-step-details>
        </template>

        <v-card-subtitle>Fields</v-card-subtitle>
        <v-card-text>
            <field-base v-for="field in sortFields(sub)"
                        :key="field._id"
                        :value="field.value"
                        :field="field"
                        readonly></field-base>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex';
    import { sortFieldsByOrder } from '@/helpers/sorts';

    import fieldBase from '@/components/field-base';
    import fileList from '@/components/file-list';
    import previousStepDetails from '@/components/submission-previous-steps-details';
    import submissionIndicators from '@/components/submission-indicators';
    import submitterDetails from '@/components/submitter-details';

    export default {
        components: {
            fieldBase,
            fileList,
            previousStepDetails,
            submissionIndicators,
            submitterDetails,
        },
        props: {
            itemId: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapState('myTasks/history', {
                sub(state) {
                    return state.items[this.itemId];
                },
            }),
        },
        methods: {
            downloadFile(fileId) {
                this.$store.dispatch('myTasks/history/downloadSubmissionFile', {
                    historyRecordId: this.sub.historyRecordId,
                    itemId: this.sub.step._id,
                    fileId,
                });
            },
            sortFields(sub) {
                const fields = sub.step.fields.slice();
                return fields.sort(sortFieldsByOrder(fields));
            },
        },
    };
</script>