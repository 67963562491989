<template>
    <v-card class="mb-4">
        <v-card-title class="justify-space-between flex-nowrap">
            <div :title="`${item.stepName} Notes: ${item.title}`"
                 class="text-truncate pr-2">
                <span class="mr-2">{{item.stepName}} Notes:</span><em>{{item.title}}</em>
            </div>
            <submission-indicators :submission="item"></submission-indicators>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-space-between flex-nowrap">
            <div class="text-truncate"
                 :title="item.project.name">{{item.project.name}}</div>
            <div class=" pt-1">
                <span class="d-none d-md-inline mr-1">Completed:</span>
                <span>{{$displayDate(item.actionedDate)}}</span>
            </div>
        </v-card-subtitle>
        <template v-if="item.files">
            <v-card-subtitle class="pb-0">Files</v-card-subtitle>
            <file-list :files="item.files"
                       @file-selected="downloadFile($event)"></file-list>
        </template>
        <v-card-text>
            <v-textarea v-model="item.fieldValue"
                        label="Notes"
                        outlined
                        readonly
                        rows="5"></v-textarea>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex';

    import fileList from '@/components/file-list';
    import submissionIndicators from '@/components/submission-indicators';

    export default {
        components: {
            fileList,
            submissionIndicators,
        },
        props: {
            itemId: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapState('myTasks/history', {
                item(state) {
                    return state.items[this.itemId];
                },
            }),
        },
        methods: {
            downloadFile(fileId) {
                this.$store.dispatch('myTasks/history/downloadSubmissionFile', {
                    // can come through as either
                    historyRecordId: this.item.historyRecordId,
                    itemId: this.itemId,
                    fileId,
                });
            },
        },
    };
</script>