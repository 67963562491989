<template>
    <div class="d-none">lazy trigger...</div>
</template>
<script>
    export default {
        name: 'history-page-trigger',
        created() {
            this.$store.dispatch('myTasks/history/loadNextPage');
        },
    };
</script>