<template>
    <app-navigation heading="My Tasks"
                    class="mb-4">
        <v-container class="mh-100"
                     :class="{'d-flex align-center justify-center': noSubsToShow }">
            <template v-for="item in items">
                <historic-collated-notes v-if="item.action === 'Collated Notes Completed'"
                                         :key="item.id"
                                         :item-id="item.id"></historic-collated-notes>
                <historic-step v-if="item.action === 'Step Completed'"
                               :key="item.id"
                               :item-id="item.id"></historic-step>
            </template>
            <template v-if="loading">
                <submission-skeleton v-for="i in 10"
                                     :key="'skeleton-' + i"></submission-skeleton>
            </template>
            <div v-if="noSubsToShow"
                 class="d-flex align-center justify-center">
                <div>
                    <h2 class="text-center">You are yet to edit any submissions!</h2>
                </div>
            </div>
            <v-lazy v-else-if="items.length && !loadedAll"
                    :key="pageNext">
                <page-trigger></page-trigger>
            </v-lazy>
        </v-container>
    </app-navigation>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';

    import appNavigation from '@/components/app-navigation';
    import historicCollatedNotes from '../components/historic-collated-notes';
    import historicStep from '../components/historic-step';
    import pageTrigger from '../components/history-page-trigger';
    import submissionSkeleton from '@/components/submission-details-skeleton';

    export default {
        name: 'editor-history',
        components: {
            appNavigation,
            historicCollatedNotes,
            historicStep,
            pageTrigger,
            submissionSkeleton,
        },
        computed: {
            noSubsToShow() {
                return !this.loading && !this.items.length;
            },
            ...mapState('myTasks/history', {
                items: 'order',
                loadedAll: 'loadedAll',
                loading: 'loading',
            }),
            ...mapGetters('myTasks/history', ['pageCurrentPosition', 'pageNext']),
        },
        created() {
            if (this.pageCurrentPosition === 0 && !this.loadedAll)
                this.$store.dispatch('myTasks/history/loadNextPage');
        },
    };
</script>